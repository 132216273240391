@import '../../../colors.scss';

.navbarWrapper {
    background-color: $fourth !important;
    color: $primary !important;
}

.navbarButton {
    border-bottom: 2px solid $fourth !important;
    border-radius: 0px !important;

    .navbarText {
        color: $primary !important;
        text-transform: capitalize !important;
    }
}

.navbarButton:hover {
    transition: 0.5s ease-in-out !important;
    border-radius: 0px !important;
    background-color: $white !important;

    .navbarText {
        color: $primary !important;
        text-transform: capitalize !important;
    }
}

.navbarButtonActive {
    background-color: rgba($primary , 0.15);
    border-radius: 8px;

    .navbarText {
        // font-weight: bold !important;
        color: $primary !important;
        text-transform: capitalize !important;
    }
}

.loginButton {
    background-color: transparent !important;
    color: $black !important;
    font-weight: bold !important;
    border-radius: 0px !important;
    border: none;
    text-transform: capitalize !important;
}