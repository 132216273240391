@import '../../../colors.scss';

.slideShowBackgroundImage {
    width: 100%;
    position: absolute;
    height: 70vh;
}

.SlideShowWrapper {
    position: absolute;
    top: 0;
    height: 70vh;

    .SlideShowItemContainer {
        width: 100%;
        height: 100%;

        .SlideShowItemImage {
            width: 100%;
            height: 100%;
        }

        .SlideShowtemOverlay {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            .SlideShowtemOverlayTitle {
                text-transform: capitalize;
                color: $white;
                font-weight: bold;
            }

            .SlideShowtemOverlaySubTitle {
                text-transform: capitalize;
                color: rgba($white , 0.8);
                margin: 32px 0px;
            }

            .SlideShowtemOverlayButton1 {
                background-color: transparent !important;
                color: $white !important;
                border-radius: 0px !important;
                // border: 1px solid var(--white) !important;
                padding: 8px 16px !important;
            }

            .SlideShowtemOverlayButton2 {
                background-color: transparent !important;
                border: 1px solid $white !important;
                color: $white !important;
                border-radius: 0px !important;
                padding: 8px 16px !important;
            }

            .SlideShowtemOverlayButton3 {
                background-color: rgba($primary , 0.8) !important;
                color: $white !important;
                border-radius: 100px !important;
                padding: 8px 16px !important;
            }
        }
    }
}

.SlideShowtemOverlayButton1:hover {
    transform: scale(1.05) !important;
    transition: transform 0.5s ease !important;
}

.swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    background: $white !important;
}