@import '../../../colors.scss';

.ScenariosComponentUpdated {
    margin: 32px 0;

    .scenario-card {
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        height: 300px;

        &:hover {
            border: 2px solid $secondary;
        }
    }

    .scenario-content {
        .scenario-content-image {
            background-color: $primary;
            display: inline-block;
            padding: 8px;
            border-radius: 24px;
            margin-bottom: 16px;

        }

        .scenario-content-h5 {
            color: $primary;
            font-weight: bold;
            height: 80px;
        }

        .scenario-content-span {
            color: $secondary;
        }
    }

    .selected-scenario-details {
        margin-top: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;

        .selected-scenario-image {
            margin-bottom: 32px;
            padding: 16px;
            border-radius: 16px;
            width: 50%;
            max-width: 200px;
            height: auto;
            background-color: $primary;
        }
    }
}