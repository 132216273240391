@import '../../../colors.scss';

.HomePageFirstComponentWrapper {
    background-color: $third;
    padding: 16px 16px 100px 16px;
    text-align: center;

    .HomePageFirstComponentTitlesContainer {
        color: $secondary;
    }
}

.HomePageFirstComponentDataContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0px;
    background-color: $third;

    .HomePageFirstComponentDataBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        background-color: $white;
        height: 350px;
        width: 400px;
        margin-bottom: 48px;
        padding: 16px;

        .HomePageFirstComponentDataImage {
            background-color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            border-radius: 100px;
        }

        .HomePageFirstComponentDataText {
            // padding: 16px;
            color: $secondary !important;
            font-weight: bold;
        }
    }
}