@import '../../colors.scss';

.contactUsWrapper {
    width: 100%;
    height: 100vh;
    // background-color: $fourth;
    background-image: url(../../assets/images/page-background.jpg);

    .contactUsContainer {
        background-color: rgba(255, 255, 255, 0.13);
        border: 2px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
        backdrop-filter: blur(10px);
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        padding: 16px;
        border-radius: 16px;

        .contactUsTitle {
            color: $secondary;
        }

        .contactUsInputLabel {
            color: $secondary !important;
            font-weight: bold !important;
        }

        .contactUsInput {
            color: $secondary !important;
            border: 1px solid $secondary !important;
            outline: none !important;

            &:hover {
                outline: none !important;
            }
        }


        .contactUsRandomNumber {
            color: $secondary;
            // border: 1px solid $secondary;
            width: auto;
            display: inline-block;
            border-radius: 8px;
            padding: 4px 16px;
        }

        .phoneNumberInput {
            border: 1px solid $secondary !important;
            padding: 6px 8px;
            border-radius: 0.25rem;

            .PhoneInputInput {
                border: none !important;
                outline: none !important;
                background-color: transparent;
                color: $secondary !important;
            }
        }


        .react-datepicker-wrapper {
            width: 100%;

            .dateLossInput {
                width: 100% !important;
                border: 1px solid $secondary !important;
                padding: 8px;
                border-radius: 0.25rem;
                background-color: transparent !important;
                color: $secondary !important;
            }
        }

        .submitButton {
            background-color: $primary !important;
            color: $white !important;
            padding: 8px 0px;
            border-radius: 8px !important;
        }
    }
}

.css-4jnixx-MuiStack-root {
    padding: 0 !important;
}