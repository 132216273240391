@import '../../../colors.scss';

.AboutUsSecondComponentWrapper {

    .AboutUsSecondComponentTitlesContainer {
        padding-right: 16px;

        .AboutUsSecondComponentTitle {
            color: $primary;
            font-weight: bold;
            margin-bottom: 16px;
        }

        .AboutUsSecondComponentSubTitle {
            color: $secondary;
        }

        .AboutUsSecondComponentText {
            color: $secondary;

            .AboutUsSecondComponentBoxCheck {
                color: $primary;
            }
        }
    }

    .AboutUsSecondComponentCardsContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .AboutUsSecondComponentCardsContainerCard {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            width: 500px !important;
            padding: 8px;

            .AboutUsSecondComponentCardsContainerBox {
                .AboutUsSecondComponentCardsContainerContent {
                    h2 {
                        color: $primary;
                    }

                    h4 {
                        color: $secondary;
                    }

                    p {
                        color: $secondary;
                    }
                }
            }
        }
    }
}