@import '../../../colors.scss';

.HomePageFourthComponentWrapper {
    padding: 32px 0px;

    .HomePageFourthComponentTitlesContainer {
        text-align: center;

        .HomePageFourthComponentTitle {
            color: $primary;
            font-weight: bold;
            padding-bottom: 24px;
        }
    }

    .HomePageFourthComponentCardsContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-top: 32px;

        .HomePageFourthComponentCard {
            // background-color: $secondary;
            border-radius: 0px;
            width: 400px;
            height: 300px;
            padding: 24px;
            cursor: pointer;

            .HomePageFourthComponentCardIconContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 8px;
                border-radius: 16px;
                color: $secondary;
                // background-color: rgba($third , 0.5);
                // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                transition: 0.5s ease-in-out;
            }

            .HomePageFourthComponentCardTitle {
                color: $secondary;
                height: 70px;
                font-weight: bold;
                text-align: center;
            }

            .HomePageFourthComponentCardTitlesContainer {
                display: flex;
                text-align: center;
                color: $secondary;
                margin-top: 8px;
                transition: 0.3s ease-in-out;

                .HomePageFourthComponentCardSub {
                    opacity: 0.8;
                }
            }

            .HomePageFourthComponentButtonContainer {

                .HomePageFourthComponentButton {
                    opacity: 0;
                    transition: 0.5s ease-in-out !important;
                    color: $white ;
                    margin-top: 16px;
                    font-size: 12px !important;
                }
            }
        }
    }
}

.HomePageFourthComponentCard:hover {
    // .HomePageFourthComponentCardIconContainer {
    //     transform: scale(0.9);
    // }

    // .HomePageFourthComponentCardTitlesContainer {
    //     margin-top: -2px !important;
    // }

    .HomePageFourthComponentButton {
        opacity: 1 !important;
    }
}