@import '../../../colors.scss';


.HomePageThirdComponentWrapper {
    padding-top: 48px;
    margin-bottom: 100px;
    height: 60vh;

    .HomePageThirdComponentContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 60vh;

        .HomePageThirdComponentDataBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .HomePageThirdComponentDataImage {
                background-color: $primary;
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
            }

            .HomePageThirdComponentDataText {
                text-align: center;
            }
        }
    }

}