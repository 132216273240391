@import '../../../colors.scss';

.systemFirstComponent {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.systemFirstComponentMainContent,
.systemFirstComponentSidebar {
    transition: all 0.3s ease;
}

.systemFirstComponentContentTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: $secondary;
    font-weight: bold !important;
    margin-bottom: 16px;
}

.systemFirstComponentContent {
    color: $secondary;
}

.systemFirstComponentSideContent {
    margin-bottom: 0px;
    padding: 16px;
    border-radius: 8px;

    .systemBoxCheck {
        color: $primary !important;
    }
}

.systemFirstComponent .systemFirstComponentSidebar:hover .systemFirstComponentSideContent {
    cursor: pointer;
}