@import '../../../colors.scss';

.AboutUsFirstComponentContainer {
    width: 100%;
    height: 80vh;
    position: relative;

    .AboutUsFirstComponentImage {
        width: 100%;
        height: 100%;
    }

    .AboutUsFirstComponentOverlay {
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &__Title {
            text-transform: capitalize;
            color: $secondary;
            font-weight: bold;
        }

        &__SubTitle {
            text-transform: capitalize;
            color: rgba($secondary , 0.8);
            margin: 32px 0px;
        }
    }
}