@import '../../../colors.scss';

.tab-container {
    max-width: 100%;
    margin: 32px auto;
    display: flex;
}

.tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    // background-color: $secondary;
    border-radius: 0px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    border: none;
    color: $primary;
    background-color: transparent;
    cursor: pointer;
    transition: font-weight 0.3s ease;
    outline: none;
    font-size: 16px;
    white-space: nowrap;
}

.tab.active {
    background-color: transparent;
    border-radius: 0px;
    color: $primary;
    border-right: 2px solid $primary;
    font-weight: bold;
}

.tab-content {
    margin-left: 16px;
    padding: 16px;
    border-radius: 0px;
}

/* Additional styling to simulate content cards, based on your image */
.tab-content div {
    padding: 10px;
    color: #333;
}

.tab-panel {
    display: none;
}

.tab-panel.active {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.hidden {
    display: none;
}