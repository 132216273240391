@import '../../../colors.scss';

.HomePageSecondComponentContainer {
    height: 80vh;

    .HomePageSecondComponentTitleContainer {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .HomePageSecondComponentContentContainer {
        display: flex;
        align-items: flex-start;
        padding: 16px;
        flex-wrap: wrap;

        .HomePageSecondComponentBoxContainer {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .HomePageSecondComponentTitle {
                color: $secondary;
                font-weight: bold;
            }

            .HomePageSecondComponentSubTitle {
                margin-top: 16px;
                color: $secondary;
            }

            .HomePageSecondComponentBoxTitle {
                color: $primary !important;
                font-weight: bold !important;
            }

            .HomePageSecondComponentButton {
                margin-top: 16px;
                border-radius: 8px !important;
                border: 2px solid $primary !important;
                text-transform: capitalize !important;
                color: $primary !important;
                font-weight: bold;

                &:hover {
                    border: 1px solid $white !important;
                    color: $white !important;
                    background-color: $primary !important;
                }
            }
        }
    }
}